._61vxfrtNBpJ1r6VLIFzHx {
  fill: none;
  stroke: #aac;
  stroke-width: 0.5px;
  stroke-opacity: 0.4;
}

.IARieYG8VKNQ9hazADg35 {
  fill: rgb(240, 244, 249);
  stroke: rgb(175, 197, 219);
}

._43mQ9H96FX-STmUnOF3Z5 {
  fill: rgb(238, 240, 243);
  stroke: rgb(148, 163, 183)
}

._43mQ9H96FX-STmUnOF3Z5:hover {
  fill: rgb(224, 227, 237);
}

._107Kn3BHmz0z4ulSv9OsKe {
  fill: rgb(90, 112, 140);
  stroke: rgb(148, 163, 183);
  cursor: pointer;

  --highlight-color: rgb(245, 125, 35)
}

._107Kn3BHmz0z4ulSv9OsKe:hover {
  fill: var(--highlight-color);
  stroke: #fff;
  stroke-width: 3;
}

._107Kn3BHmz0z4ulSv9OsKe._39ymi1A-6-leVmsz6hz60t {
  fill: var(--highlight-color);
  stroke: #fff;
  stroke-width: 2;
}
